import React from 'react';

import '../../index.css'; // Zorg ervoor dat je CSS-bestand is geïmporteerd

const PreviewPage = () => {
  return (
    <div className="container">
      

      <h2>Cursus</h2>
      <p className="left-aligned"></p>
      <p><b>Module 1</b><br></br>aanpak fight-flight stand<br></br>
      Module 2<br></br>zelflerende vermogen brein<br></br>
      Module 3<br></br>zelfhelend vermogen, leefstijl<br></br>
      Module 4<br></br>cogn gedragsverandering, de-identificatie<br></br>
      Module 5<br></br>?<br></br>
      Module 6<br></br>?
      </p><p>zelfhelend vermogen (integratie)</p>
            
      <textarea placeholder="*een webinar waarin wordt uitgelegd hoe tinnitus kan 
            ontstaan en waarin uitgelegd wordt hoe we
              de anatomische neurologische route middels therapie beinvloeden en welke de basis vormt voor onze breintraining <br></br> <br></br>
              *een online assessment; het assessment bestaat uit een uitgebreide anamnese om een goed beeld te vormen van de tinnitus klachten. En 
              waarin wordt gekeken wat er voor behandelmogelijkheden zijn en er worden bruikbare tips gegeven waar je direct mee aan de slag kunt. Er worden
              korte en lange termijn doelen opgesteld; alles in samenspraak en op maat. Het assesment bestaat uit 2 sessies van 45 min, een om de problemen en mogelijkheden 
              in kaart te brengen en een om te evalueren en waar nodig bij te stellen. "></textarea>
    </div>
  );
};



export default PreviewPage;
