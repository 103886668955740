// src/App.js
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { AuthWrapper } from './AuthContext';
import MainLayout from './layouts/MainLayout';
import HomePage from './components/pages/HomePage';
import Orofaciaallayout from './layouts/MainLayout';
import KaakfysiotherapiePage from './components/pages/KaakfysiotherapiePage';
import CraftaPage from './components/pages/CraftaPage';
import VestibulairetrainingPage from './components/pages/VestibulairetrainingPage';
import LoginPage from './components/pages/LoginPage';
import PreviewPage from './components/pages/PreviewPage';
import DashboardPage from './components/pages/DashboardPage';

import './index.css';

const App = () => {
  return (
    <AuthWrapper>
    <Routes>
      <Route path="/" element={<MainLayout />}>
      <Route index element={<HomePage />} />

      <Route path="orofaciaal" element={<Orofaciaallayout />}>
        <Route path="kaakfysiotherapie" element={<KaakfysiotherapiePage />} />
        <Route path="crafta" element={<CraftaPage />} />
        <Route path="vestibulairetraining" element={<VestibulairetrainingPage />} />
      </Route>

        
        <Route path="login" element={<LoginPage />} />
        <Route path="preview" element={<PreviewPage />} />
        <Route path="dashboard" element={<DashboardPage />} />
      </Route>
    </Routes>
    </AuthWrapper>
    
  );
};

export default App;

