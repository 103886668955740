import React from 'react';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';

const DashboardPage = () => {
  const docs = [
    {
      uri: '/mattias_desmet.pdf', // Local PDF file served from public directory
      fileType: 'pdf',
      fileName: 'mattias_desmet.pdf',
    },
  ];

  return (
    <div>
      <h1>Documents Demo</h1>
      <div style={{ height: 1000 }}>
        <DocViewer documents={docs} pluginRenderers={DocViewerRenderers} />
      </div>
    </div>
  );
};

export default DashboardPage;