import { Outlet, NavLink } from "react-router-dom";

export default function ModulesLayout() {
    return (
      <div className="container"> 
      <div className="modules-layout">
        
      

      <h2>Cursus</h2>
      
        
            
            <p>module 1</p>
            <p>voorbereidingsfase</p>
            <p>Gewoontes<br></br><br></br>we gaan in deze fase onderzoeken welke factoren voor jou een rol spelen. Ieder mens is uniek. We gaan in deze 
            fase kijken of er voldoende inzicht is in "de anatomie van tinnitus"; of ernstige dingen moeten worden uitgesloten; in welke mate de tinnitus 
            het dagelijks functioneren beperkt; welke emoties er spelen en welke ideeen je hebt over tinnitus en of je gevoelig bent voor prikkels en 
            of je in staat bent om je af te sluiten voor prikkels. In deze fase gaan we kijken in welk profiel je past; we gaan een vragenlijst afnemen, 
            en we informeren je over "de anatomie van tinnitus" <b>Maar bovenal is tinnitus behandelbaar!</b>
            

              <br></br><br></br>
               </p>

            <nav>
            <NavLink to="spoorboekje">Spoorboekje</NavLink>
        <NavLink to="herkenning">Hs1 Herkenning</NavLink>
        <NavLink to="an1">Anatomie deel 1</NavLink>
        <NavLink to="an2">Anatomie deel 2</NavLink>
        
        
      </nav>

      <Outlet />

      <hr />
      <p>module 2</p>
            <p>aanpak Fight flight stand (trainen)</p>
            <p>tinnitus, angst, catastoferen, hypervigilantie, disuse <br></br><br></br>In deze module staan we stil bij de rol van stress. Het brein is overprikkeld en gesensitiseerd. 
            Je kunt op dit moment niet veel aan...het is al gauw te veel. Dit wordt aspecifieke arousal genoemd. Het is lastig om je af te 
            sluiten voor prikkels. Het is overleven en als het geluid intens 
            is, beinvloedt dit je gemoedstoestand en het is lastig om te ontspannen. In deze fase is het belangrijk om jezelf de kans te geven 
            om te herstellen. Je bent voorbeeldig en wil het graag goed doen, en doet veel voor anderen. Maar in deze fase is het belangrijk 
            dat je meer lichaamsgericht gaat werken. Fysiotherapie kan daarbij werken, maar ook ontspanning en maskeren. In de volgende module 
            gaan we met exposure aan de slag en zoeken we intense prikkels op. Dit kan alleen als we leren te ontspannen, maar we maken ook gebruik
            van de principes "zwakte wordt opgelost met stijvigheid" en "je kunt pas loslaten als je een beetje houvast hebt". Ontspannen kan aan de 
            hand van mindfullness en via ademhaling. Meestal is er sprake van een vicieuze cirkel, waar moeilijk uit te komen is. Het geluid is dominant,
            werkt catastroferend en
            er is angst dat het erger wordt en dit levert alleen maar meer spanning op.

              <br></br><br></br>
               </p>

              <nav>
        <NavLink to="kaakfysio">Kaakfysio</NavLink>
        <NavLink to="stress">Stress</NavLink>
        <NavLink to="hs2">Hs2 neuroplasticiteit</NavLink>
        <NavLink to="ridder">Ridder</NavLink>

      </nav>

      <Outlet />

      
            
            
   
      <hr />
<p>module 3</p>
<p>zelflerend vermogen brein (trainen)</p>
<p>Fobie, neuroplasticity, exposure, trainen<br></br><br></br>Nu het lichaamsgerichte meer eigen is, wordt het tijd om stil te staan bij hoe het brein werkt. 
Het brein is alert en wil jou informeren of er gevaar dreigt. Dit kan functioneel zijn, maar niet bij tinnitus. Je hebt de neiging 
om de heftigheid te maskeren en wilt ervoor weglopen, maar je moet je brein juist leren, om ernaar toe te gaan en het brein te leren 
dat er geen bedreiging is. Dit wordt exposure genoemd; voor het brein, maar kan ook in de vorm van embodiment exposure. 

  <br></br><br></br>
   </p>
   <nav>
        <NavLink to="neuroplasticity">Neuroplasticity</NavLink>
        <NavLink to="hs3">Hs3 Aanpak</NavLink>
        <NavLink to="bodyscan">bodyscan</NavLink>
        <NavLink to="bilateral">bilateral stimulation</NavLink>
        <NavLink to="ademhaling">Ademhaling</NavLink>
        

      </nav>

      <Outlet />
      <hr />
   <p>module 4</p>
   <p>zelfhelend vermogen (integratie)</p>
            <p>Leefstijl<br></br><br></br>Je kunt uitgaan van het zelfhelend vermogen van het lichaam. Het lichaam is ongelooflijk sterk, maar je moet
            het wel ondersteunen. Er is een optimum: niet te veel, niet te weinig. Duursport is goed, maar ook weer niet overdreven. Vooral het 
            immuunsysteem is gebaat bij een goede uitwisseling met de omgeving...

             </p>
              <nav>
              <NavLink to="hs4">Hs4 Op maat</NavLink>
              <NavLink to="huber">Huber</NavLink>
              <NavLink to="joey">Joey</NavLink>
              </nav>

              <Outlet />
              <hr />
              <p>module 5</p>
            <p>zelfhelend vermogen (integratie)</p>
            <p>Resoneren<br></br><br></br>Nu je geleerd hebt dat het lichaamsgerichte, de brein training en de exposure manieren zijn om de tinnitus 
            te sturen, wordt het zaak om het geleerde toe te passen in het dagelijks leven. Uit de context bv op je zolderkamer ademhalingsoefeningen 
            doen lukt vaak nog wel, maar de integratie op je werk of op een verjaardag is een uitdaging. We zijn geneigd om te kijken naar wat niet goed
            gaat, maar kijken naar mogelijkheden is ook een optie. Positieve gezondheid en kwaliteit van leven hangen af van wat we belangrijk vinden 
            in het leven. De een vindt sporten belangrijk, de ander sociale contacten etc

              <br></br><br></br>
              </p>

              <nav>
              
              <NavLink to="hs5">Hs5 Trainbaar</NavLink>
              
              </nav>

              <Outlet />
              <hr />
              
            
              <p>module 6</p>
            <p>Actualize (integratie)</p>
            <p>Doen en blijven doen<br></br><br></br>we zitten aan het einde van het traject. De reintegratie verloopt vaak in 3 fasen: in de eerste fase vallen mensen om;
            in de tweede fase worden mensen bewust en "leren" ze voelen, ze "leren" om niet zo voorbeeldig te zijn, om niet continu te "pleasen"; om van daaruit
            de "oude ik" weer terug te vinden. In de derde fase is het belangrijk om authentiek te handelen. Dit is spannend, want we hebben heel erg geleerd om
            dienstbaar te zijn en voor de ander te zorgen. In de tweede fase stond het zelflerend en zelfhelend vermogen centraal...dit lijkt best wel egocentrisch, maar
            je moet eerst goed voor jezelf zorgen, wil je voor de ander zorgen. In deze laatste fase leren we wat jij nu echt belangrijk vindt en om daar ook naar te
            handelen. We vervallen vaak in oude routines, en nieuwe routines zijn vaak spannend. Maar je zult zien als je de nieuwe koers aanhoudt, dan levert dit heel
            veel op. Natuurlijk gaat het met vallen en opstaan en je moet lef hebben. Maar netto zet je je zelf in je kracht en je lichaam gaat daar in mee: de klachten
            gaan naar de achtergrond.
              <br></br><br></br>
              </p>

              <nav>
              <NavLink to="hs6">Hs6 pos mindset</NavLink>
              <NavLink to="filosofe">Chaos</NavLink>
              <NavLink to="intentioneel">Intentioneel leven</NavLink>
              </nav>
              <hr />

   <Outlet />

            </div>
            </div>
    )
  }

