import React from 'react';

import ReactPlayer from 'react-player';





function MyPlayer () {
    return (
        <div>
        <h1>My Custom Player</h1>
        {}
        <ReactPlayer controls={true} url={"https://www.youtube.com/watch?v=3_SEUhjSZ7E"} heigth="400" width="500" />
        </div>
    );
}

export default MyPlayer;