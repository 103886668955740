

import { Outlet, NavLink } from "react-router-dom";


export default function Orofaciaallayout() {
    return (
      <>
      <div className="containereen">
         

         <p className='Tinnitusbehandeling'>
          De kaakfysiotherapie of orofaciale therapie in de aanpak van tinnitus klachten is gebaseerd op het aanpakken van de fysieke triggers 
          die de klachten kunnen aanjagen en onderhouden. Het is belangrijk dat de kaak en nek "vrij" zijn, zodat het sympathische zenuwstelsel niet continue
          wordt aangejaagd en het lichaam kans heeft om te herstellen.
          
          
          
          </p>
        
        
      </div>
      
      <div className="containertwee">
          <strong>Kaakfysiotherapie</strong>
          
          <div className="app-picture">
            <img alt="boris" src="./assets/istockphoto-1358585412-1024x1024.jpg"/>
        
         </div>
      </div>
      <div><h2>Orofaciale therapie</h2></div>
      
      
    
    
      <div className="orofaciaal-layout">
        
            
            <p>Met Orofaciale therapie, ook wel kaakfysiotherapie genoemd, behandelen we somatosensorische tinnitus. De tinnitus is dan
              fysiek te beinvloeden: het is moduleerbaar via hands on technieken direct op het hoofd en/of op de kaak. Dit is zeer gunstig: de prognose is zeer goed. De kaakfysiotherapeut 
              werkt vaak samen met de tandarts, vooral als er sprake is van spierspanningen
              van de kauwspieren. Dit opbouwen van spierspanningen vindt plaats als er geklemd en/of geknarsd wordt. Dit kaakklemmen kan behandeld worden met een 
              opbeetplaat in opdracht van de tandarts; en met bewustwording en
              oefeningen middels kaakfysiotherapie. <br></br><br></br>

              Ook kan de orofaciaal therapeut met zogenaamde Crafta technieken de klachten behandelen door zachte technieken op de schedeldelen toe te passen.
              <br></br><br></br>
              Tenslotte is de betrokken hersenzenuw: de nervus vestibulocochlearis betrokken bij tinnitus. Deze zenuw stuurt het geluid aan, maar ook het evenwicht. 
              Met vestibulaire training ofwel evenwichtstraining trainen we de balans en coordinatie, welke een gunstig resultaat gaat hebben op de tinnitus.

            </p>

            <nav>
        <NavLink to="kaakfysiotherapie">Kaakfysiotherapie</NavLink>
        <NavLink to="CRAFTA">CRAFTA</NavLink>
        <NavLink to="Vestibulairetraining">Vestibulaire training</NavLink>
      </nav>
      

     
        <Outlet />
      </div>
      </>
    
    )
}

