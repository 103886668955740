import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import QueryString from 'query-string';
import { API_URL } from '../../config/index';



const Onlineassessment = () => {
  const location = useLocation();

  useEffect(() => {
    const values = QueryString.parse(location.search);

    if (values.success) {
      console.log('Order placed! You will receive an email confirmation.');
    }

    if (values.canceled) {
      console.log("Order canceled -- continue to shop around and checkout when you're ready.");
    }
  }, [location.search]);

  return (
    <div>
      
      <div class="container">
        
        <b><p>Online assessment</p></b>
        <p>het assessment bestaat uit een uitgebreide anamnese om een goed beeld te vormen van de tinnitus klachten. En 
            waarin wordt gekeken wat er voor behandelmogelijkheden zijn en er worden bruikbare tips gegeven waar je direct mee aan de slag kunt. Er worden
            korte en lange termijn doelen opgesteld; alles in samenspraak en op maat. Het assesment bestaat uit 2 sessies van 40 min, een om de problemen en mogelijkheden 
            in kaart te brengen en een om te evalueren en waar nodig bij te stellen. Het online consult gaat via de applicatie "Zoom". Er kan ook een inschatting worden gemaakt of de cursus dan wel de training geindiceerd is. 
            Meld je aan via onderstaande knop en er wordt zsm contact met je opgenomen om het eerste consult in te plannen</p>

    </div>

    
    
      
      
      <section>
        <div className='product'>
          <img
            src='https://i.imgur.com/EHyR2nP.png'
            alt='The cover of Stubborn Attachments'
          />
          <div className='description'>
            <h3>Online assessment</h3>
            <h5>99.00€</h5>
          </div>
        </div>
        <form
          action={`${API_URL}/api/stripe/create-checkout-session`}
          method='POST'
        >
          <button className='button' type='submit'>
            Bestel
          </button>
        </form>
      </section>
    </div>
  );
};

export default Onlineassessment;



