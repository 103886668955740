// src/components/layouts/MainLayout.js
import React from 'react';
import { Outlet } from 'react-router-dom';




export default function MainLayout () {


  return (
    <div className="content">
      

      <Outlet />
    </div>
  
  )
}

