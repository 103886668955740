import { Outlet, NavLink } from "react-router-dom";

export default function TrainingLayout() {
  return (
    <>
     <div className="containereen">
         

         <p className='Tinnitusbehandeling'>
          De Training is gericht op het ombuigen van tinnitus. Bij trainen gaat het niet alleen om lezen en leren maar ook uit echt doen en uitproberen. De training
          kost tijd en is opgebouwd uit 3 fasen. In de eerste fase wordt stilgestaan bij het "omvallen" en het herkennen en het duiden van de ongewenste
          sensaties. In de tweede fase wordt ingegaan op het lichaamsgerichte. Dit kan bestaan uit kaakfysiotherapie en embodiment maar ook uit andere oefenvormen om vanuit het 
          voelen weer controle te krijgen en de tinnitus te sturen middels breintraining, lees: exposure training en via neuroplasticiteit. In de laatste fase vindt 
          de transfer plaats in de context en is zingeving heel belangrijk.
          
          </p>
        
        
      </div>
      
      <div className="containertwee">
          <strong>De training</strong>
          
          <div className="app-picture">
            <img alt="boris" src="./assets/kateryna-hliznitsova-kHch8uQeq3o-unsplash.jpg"/>
        
         </div>
      </div>
      <div><h1>Onderdelen</h1></div>
      
      
    <div className="training-layout">
      
          <h2>Tinnitus behandeling</h2>
          <p>Onze tinnitusbehandeling bestaat uit 4 onderdelen, te weten <br></br><br></br>*een e book waarin een andere benadering van tinnitus wordt aangereikt dan de bestaande medische curatieve behandeling<br></br> <br></br>
            *een online assessment; het assesment bestaat uit 2 sessies van 40 min
            <br></br><br></br>
            *een cursus; de cursus bestaat uit 10 weken die volledig online is te volgen. Je kunt al een kijkje nemen op de "Preview"<br></br><br></br>
            
            *een training in een groep, welke een hybride vorm heeft, bestaande uit een continue online begeleiding en bijeenkomsten in de groep. Ook de training duurt 10 weken. <br></br>Als je je wil aanmelden
            kan het via deze link: https://www.borisdrogtfysio.nl/training
            </p>

          <nav>
      <NavLink to="ebook">E book</NavLink>
      <NavLink to="inhoudsopgave">E book inhoudsopgave</NavLink>
      <NavLink to="onlineassessment">Online assessment</NavLink>
      <NavLink to="cursus">Cursus</NavLink>
    </nav>

          <Outlet />

          </div>
          </>
  )
}