// src/components/structure/Header.js
import React from 'react';
import { AuthData } from '../../AuthContext';

export const RenderHeader = () => {
  const { user, logout } = AuthData();

  return (
    <header>
      <h1>Welcome {user.isAuthenticated ? user.name : 'Guest'}</h1>
      {user.isAuthenticated && <button onClick={logout}>Logout</button>}
    </header>
  );
  
};