import { Outlet, NavLink } from "react-router-dom";

export default function ModulesLayout() {
    return (
      <div className="container"> 
      <div className="modules-layout">
        
      

      <h2>Cursus</h2>
      
        
            
            <p>module 1</p>
            <p>voorbereidingsfase</p>
            <p>Gewoontes<br></br><br></br>we gaan in deze fase onderzoeken welke factoren voor jou een rol spelen. Ieder mens is uniek. We gaan in deze 
            fase kijken of er voldoende inzicht is in "de anatomie van tinnitus"; of ernstige dingen moeten worden uitgesloten; in welke mate de tinnitus 
            het dagelijks functioneren beperkt; welke emoties er spelen en welke ideeen je hebt over tinnitus en of je gevoelig bent voor prikkels en 
            of je in staat bent om je af te sluiten voor prikkels. In deze fase gaan we kijken in welk profiel je past; we gaan een vragenlijst afnemen, 
            en we informeren je over "de anatomie van tinnitus"
            

              <br></br><br></br>
              PREVIEW CURSUS
               </p>

            <nav>
        <NavLink to="herkenningpre">Herkenning</NavLink>
        <NavLink to="an1pre">Anatomie deel 1</NavLink>
        <NavLink to="an2pre">Anatomie deel 2</NavLink>
        <NavLink to="tqpre">Tinnitus questionnaire</NavLink>
      </nav>

      <Outlet />

      <hr />
      <p>module 2</p>
            <p>aanpak Fight flight stand (trainen)</p>
            <p>Gewoontes<br></br><br></br>In deze module staan we stil bij de rol van stress. Het brein is overprikkeld en gesensitiseerd. 
            Je kunt op dit moment niet veel aan...het is al gauw te veel. Dit wordt aspecifieke arousal genoemd. Het is lastig om je af te 
            sluiten voor prikkels. Het is overleven en als het geluid intens 
            is, beinvloedt dit je gemoedstoestand en het is lastig om te ontspannen. In deze fase is het belangrijk om jezelf de kans te geven 
            om te herstellen. Je bent voorbeeldig en wil het graag goed doen, en doet veel voor anderen. Maar in deze fase is het belangrijk 
            dat je meer lichaamsgericht gaat werken. Fysiotherapie kan daarbij werken, maar ook ontspanning en maskeren. In de volgende module 
            gaan we met exposure aan de slag en zoeken we intense prikkels op. Dit kan alleen als we leren te ontspannen, maar we maken ook gebruik
            van de principes "zwakte wordt opgelost met stijvigheid" en "je kunt pas loslaten als je een beetje houvast hebt". Ontspannen kan aan de 
            hand van mindfullness en via ademhaling. Meestal is er sprake van een vicieuze cirkel, waar moeilijk uit te komen is. Het geluid is dominant,
            werkt catastroferend en
            er is angst dat het erger wordt en dit levert alleen maar meer spanning op.

              <br></br><br></br>
               </p>

              

      
            
            
   

<p>module 3</p>
<p>zelflerend vermogen brein (trainen)</p>
<p>Gewoontes<br></br><br></br>Nu het lichaamsgerichte meer eigen is, wordt het tijd om stil te staan bij hoe het brein werkt. 
Het brein is alert en wil jou informeren of er gevaar dreigt. Dit kan functioneel zijn, maar niet bij tinnitus. Je hebt de neiging 
om de heftigheid te maskeren en wilt ervoor weglopen, maar je moet je brein juist leren, om ernaar toe te gaan en het brein te leren 
dat er geen bedreiging is. Dit wordt exposure genoemd; voor het brein, maar kan ook in de vorm van embodiment exposure. 

  <br></br><br></br>
   </p>
   
   <p>module 4</p>
            <p>zelfhelend vermogen (integratie)</p>
            <p>Gewoontes<br></br><br></br>*een webinar waarin wordt uitgelegd hoe tinnitus kan 
            ontstaan en waarin uitgelegd wordt hoe we
              de anatomische neurologische route middels therapie beinvloeden en welke de basis vormt voor onze breintraining <br></br> <br></br>
              *een online assessment; het assessment bestaat uit een uitgebreide anamnese om een goed beeld te vormen van de tinnitus klachten. En 
              waarin wordt gekeken wat er voor behandelmogelijkheden zijn en er worden bruikbare tips gegeven waar je direct mee aan de slag kunt. Er worden
              korte en lange termijn doelen opgesteld; alles in samenspraak en op maat. Het assesment bestaat uit 2 sessies van 45 min, een om de problemen en mogelijkheden 
              in kaart te brengen en een om te evalueren en waar nodig bij te stellen. Er kan ook een inschatting worden gemaakt of de cursus geindiceerd is.
              <br></br><br></br>
              *de cursus die bestaat uit 10 weken, welke een hybride vorm heeft en een continue online begeleiding met interactieve werkvormen met de groep </p>

              <p>module 5</p>
            <p>leefstijl (integratie)</p>
            <p>Gewoontes<br></br><br></br>*een webinar waarin wordt uitgelegd hoe tinnitus kan 
            ontstaan en waarin uitgelegd wordt hoe we
              de anatomische neurologische route middels therapie beinvloeden en welke de basis vormt voor onze breintraining <br></br> <br></br>
              *een online assessment; het assessment bestaat uit een uitgebreide anamnese om een goed beeld te vormen van de tinnitus klachten. En 
              waarin wordt gekeken wat er voor behandelmogelijkheden zijn en er worden bruikbare tips gegeven waar je direct mee aan de slag kunt. Er worden
              korte en lange termijn doelen opgesteld; alles in samenspraak en op maat. Het assesment bestaat uit 2 sessies van 45 min, een om de problemen en mogelijkheden 
              in kaart te brengen en een om te evalueren en waar nodig bij te stellen. Er kan ook een inschatting worden gemaakt of de cursus geindiceerd is.
              <br></br><br></br>
              *de cursus die bestaat uit 10 weken, welke een hybride vorm heeft en een continue online begeleiding met interactieve werkvormen met de groep </p>
              
              <p>module 6</p>
            <p>cognitieve gedragstherapie de identificatie (integratie)</p>
            <p>Gewoontes<br></br><br></br>*een webinar waarin wordt uitgelegd hoe tinnitus kan 
            ontstaan en waarin uitgelegd wordt hoe we
              de anatomische neurologische route middels therapie beinvloeden en welke de basis vormt voor onze breintraining <br></br> <br></br>
              *een online assessment; het assessment bestaat uit een uitgebreide anamnese om een goed beeld te vormen van de tinnitus klachten. En 
              waarin wordt gekeken wat er voor behandelmogelijkheden zijn en er worden bruikbare tips gegeven waar je direct mee aan de slag kunt. Er worden
              korte en lange termijn doelen opgesteld; alles in samenspraak en op maat. Het assesment bestaat uit 2 sessies van 45 min, een om de problemen en mogelijkheden 
              in kaart te brengen en een om te evalueren en waar nodig bij te stellen. Er kan ook een inschatting worden gemaakt of de cursus geindiceerd is.
              <br></br><br></br>
              *de cursus die bestaat uit 10 weken, welke een hybride vorm heeft en een continue online begeleiding met interactieve werkvormen met de groep </p>

   <Outlet />

            </div>
            </div>
    )
  }

