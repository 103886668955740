import React from 'react';

import ReactPlayer from 'react-player';



function MyPlayer () {
  return (
      <div>
      <h1>My Custom Player</h1>
      {}
      <ReactPlayer controls={true} url={"https://www.youtube.com/watch?v=SlBaf53kZ7k"} heigth="400" width="500" />
      </div>
  );
}

export default MyPlayer;