import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import QueryString from 'query-string';
import { API_URL } from '../../config/index';



const Ebook = () => {
  const location = useLocation();

  useEffect(() => {
    const values = QueryString.parse(location.search);

    if (values.success) {
      console.log('Order placed! You will receive an email confirmation.');
    }

    if (values.canceled) {
      console.log("Order canceled -- continue to shop around and checkout when you're ready.");
    }
  }, [location.search]);

  return (
    <div>
      
      <div class="container">
        <b><p>Ebook</p></b>
        <p>In dit Ebook wordt je meegenomen in een andere nieuwe benadering van tinnitus, dan de bestaande medische curatieve behandeling. 
          Er wordt stilgestaan bij de rol van het brein, stress en het immuunsysteem. De aanpak wordt uitgebreid besproken en er wordt ingegaan op een 
          persoonlijke aanpak. De aanpak richt zich op breintraining en exposure. Ook het belang van een adequate leefstijl en een positieve mindset komen uitgebreid aan bod. 
          Het is een boek vol inzichten en zeer bruikbaar om te herstellen van tinnitus. Het doel is om tinnitus om te buigen.


</p>

          
        <div className="app-picture">
      <img alt="tinnitus" src="../../assets/tinnitus.png"/>
        
    </div>
    </div>
    

    
    
      
      
      <section>
        <div className='product'>
          <img
            src='https://i.imgur.com/EHyR2nP.png'
            alt='The cover of Stubborn Attachments'
          />
          <div className='description'>
            <h3>Ebook</h3>
            <h5>23.00€</h5>
          </div>
        </div>
        <form
          action={`${API_URL}/api/stripe/create-checkout-session`}
          method='POST'
        >
          
          <button className='button' type='submit'>
            Bestel
          </button>
        </form>
      </section>
    </div>
  );
};

export default Ebook;


