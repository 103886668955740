import React from 'react';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';

const DashboardPage = () => {
  const docs = [
    {
      uri: '/Chaos.pdf', // Local PDF file served from public directory
      fileType: 'pdf',
      fileName: 'Hs2.pdf',
    },
  ];

  return (
    <div>
      <h1>Chaos</h1>
      <div style={{ height: 500, overflowY: 'scroll' }}>
        <DocViewer documents={docs} pluginRenderers={DocViewerRenderers} />
      </div>
    </div>
  );
};

export default DashboardPage;